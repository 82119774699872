@import '../../../../variables';

.onboarding-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 15px;
	background-color: rgba($color-dark-main, 0.6) !important;
	display: flex;
	align-items: center;
	justify-content: center;

	.onboarding-modal {
		transform: none !important;
		width: 1080px;
		max-width: 100%;
		height: 600px;
		background-color: $color-white;
		border-radius: 20px;
	}

	.content-modal {
		padding: 10px;
		background-color: $color-white;
		border-radius: 20px;
		position: relative;

		.close-btn {
			position: absolute;
			top: -15px;
			right: -15px;
			width: 50px;
			height: 50px;
			background-color: $color-white;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
			cursor: pointer;
			z-index: 2;
		}

		.step {
			display: flex;

			&.d-none {
				display: none;
			}
		}

		.step-0 {
			.description {
				padding-top: 70px;
			}
		}

		.progress {
			width: 200px;
			height: 4px;
			background-color: $color-light-gray-1;
			border-radius: 5px;
			overflow: hidden;
			position: relative;
			margin-bottom: 20px;

			span {
				background-color: $color-action-1;
				border-radius: 5px;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				transition: all .5s;
			}
		}

		.description {
			padding: 46px 40px 30px 30px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 1;

			h2 {
				font-size: 36px;
				line-height: 1.2;
				font-weight: 600;
				margin-top: 0;
				margin-bottom: 20px;
			}

			p {
				font-size: 20px;
				line-height: 1.5;

				strong {
					font-weight: 600;
				}

				.obs {
					color: $color-gray-9;
				}
			}

			.info-btn {
				margin-top: 40px;

				p {
					font-size: 18px;
					line-height: 24px;
				}
			}

			.bottom-content {
				margin-top: auto;
				display: flex;
				padding-top: 15px;
			}

			.later {
				color: $color-gray-9;
				cursor: pointer;

				img {
					margin-right: 6px;
				}
			}
		}

		.control-btn {
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			cursor: pointer;
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}

		.next-btn {
			font-size: 14px;
			line-height: 1.2;
			background-color: $color-dark-main;
			border: none;
			color: $color-white;
			padding-right: 20px;
			padding-left: 20px;

			img {
				margin-left: 10px;
			}
		}

		.prev-btn {
			width: 50px;
			background-color: $color-white;
			border: 1px solid $color-gray-3;
		}

		.points {
			display: flex;
			justify-content: space-between;
			width: 100%;
			margin-top: 10px;
			margin-bottom: 10px;

			.points-wrapper {
				img {
					margin-right: 2px;

					&:last-child {
						margin-right: 0;
					}
				}

				p {
					margin-top: 10px;
					font-size: 32px;
					line-height: 38px;
					font-weight: 700;

					sup {
						font-size: 20px;
						line-height: 24px;
						font-weight: 400;
					}
				}
			}
		}

		.img-wrapper {
			background-color: $color-gray-1;
			width: 47.16%;
			min-height: 580px;
			border-radius: 10px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			z-index: 0;

			img {
				//position: absolute;
				//left: 0;
				//right: 0;
				//top: 0;
				//bottom: 0;
				//object-fit: cover;
				//width: 100%;
				//height: 100%;
				max-width: 100%;
			}
		}
	}
}

@media (max-width: 1200px) {
	.onboarding-overlay {
		.content-modal {
			.description {
				h2 {
					font-size: 30px;
				}

				p {
					font-size: 18px;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.onboarding-overlay {
		.content-modal {
			.step-0 {
				.description {
					padding-top: 39px;
				}
			}

			.description {
				padding: 20px 30px 10px 20px;

				h2 {
					margin-bottom: 15px;
				}
			}

			.progress {
				margin-bottom: 15px;
			}
		}
	}
}

@media (max-width: 767px) {
	.onboarding-overlay {
		padding: 10px;

		.onboarding-modal {
			height: calc(100vh - 20px);
		}

		.content-modal {
			height: calc(100% - 20px);

			.close-btn {
				width: 40px;
				height: 40px;
				top: -10px;
				right: -10px;
			}

			.step {
				flex-direction: column;
				height: 100%;
				overflow-x: auto;
			}

			.step-0 {
				.description {
					padding-top: 20px;

					.bottom-content {
						margin-top: 0;
					}
				}
			}

			.img-wrapper {
				order: 1;
				width: 100%;
				min-height: auto;
				min-height: 47.5vh;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					position: relative;
					object-fit: initial;
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					display: block;
				}
			}

			.description {
				order: 2;
				flex: initial;
				padding: 20px 0 0;
				display: flex;
				flex-direction: column;
				height: 100%;

				h2 {
					font-size: 24px;
					margin-bottom: 20px;
				}

				p {
					font-size: 14px;
				}

				.info-btn {
					margin-top: auto;
					width: 100%;

					p {
						font-size: 14px;
						line-height: 20px;
					}

					.btn {
						width: 100%;
					}
				}

				.progress {
					width: 100%;
					margin-bottom: 20px;
				}

				.bottom-content {
					width: 100%;
					padding-top: 20px;
					justify-content: space-between;
				}

				.later {
					text-align: center;
					margin-bottom: 10px;
					width: 100%;

					img {
						width: 18px;
					}
				}
			}

			.points {
				.points-wrapper {
					p {
						font-size: 24px;
						line-height: 28px;
						margin-top: 5px;

						sup {
							font-size: 16px;
							line-height: 20px;
						}
					}

					img {
						width: 10px;
					}
				}
			}
		}
	}
}
