@import '../../variables.scss';

footer {
  width: 100%;
  position: fixed;
  left: -100%;
  right: 0;
  bottom: 0;
  padding: 15px 0;
  background-color: #E6E6E6;
  color: $color-text-1;
  -webkit-transition: left 0.2s; /* Safari */
  transition: left 0.2s;
  transition-timing-function: ease-out;
  z-index: 10;

	.select-custom__menu {
		top: auto;
		bottom: 100%;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		border-bottom-right-radius: unset;
		border-bottom-left-radius: unset;
	}
	//.Select-option:first-child {
	//	border-top-right-radius: 4px;
	//	border-top-left-radius: 4px;
	//}
	//.Select-option:last-child {
	//	border-bottom-right-radius: unset;
	//	border-bottom-left-radius: unset;
	//}
	//.Select.is-open > .Select-control {
	//	border-top-right-radius: unset;
	//	border-top-left-radius: unset;
	//	border-bottom-right-radius: 4px;
	//	border-bottom-left-radius: 4px;
	//}

  .container-wrapper {
    -webkit-transition: all 0.2s; /* Safari */
    transition: all 0.2s;
    transition-timing-function: ease-out;

    &.nav-opened {
      width: calc(100% - 150px);
      margin-left: 150px;
    }
  }

  a {
    color: $color-text-1;
    vertical-align: middle;
  }

  .left {
    p {
      font-size: 11px;
      margin-top: 12px;
    }
  }

  .right {
    margin-left: auto;
    text-align: right;
  }

  .app {
    font-weight: 600;
    font-size: 14px;
    display: inline-block;

    img {
      background-color: $color-dark-1;
      border-radius: 8px;
      padding: 6px 7px;
      margin-right: 6px;
    }
  }

  .social-list {
    display: inline-block;
    margin-left: 26px;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 30px;
      background-color: $color-light-gray;
      position: absolute;
      top: -2px;
      left: -18px;
    }

    li {
      display: inline-block;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footer-menu {
    margin-top: 5px;

    li {
      display: inline-block;
      font-size: 11px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.open {
    left: 0;
  }

  &.footer-simple {
    left: 0;
    background-color: transparent;
    z-index: 2;

    .left {
      -js-display: flex;display: flex;
      align-items: flex-end;
    }
  }
}

.content-footer {
  -js-display: flex;display: flex;
}


@media (max-width: 575px) {
  footer {
    display: none;
  }
}
