@import '~AppSrc/variables.scss';

.ReactModal__Content.installation-error-modal {
	position: absolute;
	top: 50%;
	left: 50%;

	.title { padding: 0; margin-top: 15px; }
}

