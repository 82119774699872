$color-white: #ffffff;
$color-black: #000000;
$color-bg-main: #f5f5f5;
$color-light-gray: #CCCCCC;
$color-light-gray-1: #F2F2F2;
$color-gray: #E5E5E5;
$color-gray-1: #D9D9D9;
$color-gray-2: #E6E6E6;
$color-gray-3: #999999;
$color-gray-4: #C4C4C4;
$color-gray-5: #8E959D;
$color-gray-6: #E9EAEC;
$color-gray-7: #DDDFE2;
$color-gray-8: #666666;
$color-gray-9: #606A75;
$color-gray-10: #8D8295;
$color-gray-11: #C9C6CB;
$color-dark-1: #1C2B3B;
$color-dark-2: #453353;
$color-dark-3: #250F35;
$color-dark-4: #333333;
$color-dark-5: #250E35;
$color-dark-6: #1c2b3b;
$color-dark-7: #240E35;
$color-dark-8: #7E6093;
$color-dark-main: #250F35;
$color-text-1: #250F35;
$color-text-2: #92879A;
$color-text-3: #9EA4AB;
$color-gold: #E2CC5B;
$color-action-1: #01FFA4;
$color-action-2: #FD5F00;
$color-action-3: #FF4FCF;
$color-action-4: #E8FF27;
$color-yellow: #CC8736;
$color-yellow-1: #FFD613;
$color-yellow-2: #C2A925;
$color-red: #E61826;
$color-green: #06CD69;
$color-green-1: #4EC225;
$color-error: #FF0F00;
$color-purple: #5A406C;
$color-pink: #FF4FCF;
$color-red-2: #C22525;
$color-red-3: #FF1C4C;
$color-green-2: #37B858;
$color-warning: #F5E7CB;
$color-profile: #1FC9FF;
$color-violet: #6D20EC;
$color-light-blue: #D5EFF7;
$color-blue: #00B6EF;
$color-blue-1: #00A8DD;

//Gradients
$gradient-gold: rgb(225,202,84) 0%, rgb(255,251,224) 16.57%, rgb(217,165,31) 21.73%, rgb(253,239,113) 39.78%, rgb(166,124,0) 53.04%, rgb(248,240,162) 64.09%, rgb(255,255,255) 66.3%, rgb(245,223,147) 83.98%, rgb(238,200,62) 100%;
$gradient-is-sticky: rgb(85,85,85) 0%, rgb(169,169,169) 16.57%, rgb(68,68,68) 21.73%, rgb(135,135,135) 39.78%, rgb(51,51,51) 53.04%, rgb(152,152,152) 64.09%, rgb(255,255,255) 66.3%, rgb(118,118,118) 83.98%, rgb(101,101,101) 100%;

