@import '../../variables.scss';

nav {
	height: 100%;
	width: 50px;
	position: fixed;
	left: 0;
	overflow: hidden;
	background-color: $color-white;
	-webkit-transition: width 0.2s;
	transition: width 0.2s;
	transition-timing-function: ease-out;
	box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.1);
	z-index: 11;

	.nav-inner-wrapper {
		margin-top: 13px;
	}

	&.open {
		.menu-logo {
			.menu-logo-1 {
				display: none;
			}

			.menu-logo-2 {
				display: inline-block;
			}
		}
	}

	.menu-logo {
		width: 100%;
		height: 40px;
		position: relative;

		&:after {
			content: "";
			width: 70%;
			height: 1px;
			background-color: #D9D9D9;
			position: absolute;
			right: 15%;
			bottom: 0;
		}

		img.menu-logo-1 {
			max-height: 32px;
			margin: 0 auto 0 auto;
			display: block;
		}

		img.menu-logo-2 {
			max-width: 70%;
			max-height: 32px;
			margin: 7.5px 15%;
			display: none;
		}
	}

	.main-nav {
		margin-top: 25px;
		padding-left: 10px;

		li {
			position: relative;
			margin-bottom: 30px;
			z-index: 10;
			white-space: nowrap;

			&.profile-info-mobile {
				display: none;
			}

			a {
				display: block;
				cursor: pointer;

				.icon-wrapper {
					position: relative;
					display: inline-block;
					width: 30px;
					height: 30px;
					vertical-align: middle;

					img {
						display: block;
						width: 30px;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				span {
					display: inline-block;
					position: relative;
					margin-left: 20px;
					left: -20px;
					opacity: 0;
					vertical-align: middle;
					font-size: 18px;
					line-height: 22px;
					color: $color-text-1;
					font-weight: 400;
					-webkit-transition: all 0.2s; /* Safari */
					transition: all 0.2s;
					transition-timing-function: ease-out;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&.active {

				a {

					img {

					}

					span {
						font-weight: 700;
						color: $color-dark-1;
					}
				}

				&::before {
					content: "";
					display: block;
					height: 81px;
					width: 118px;
					position: absolute;
					left: -10px;
					top: 62%;
					transform: translateY(-50%);
					background-image: url("./img/bg-menu-active.png");
					background-repeat: no-repeat;
					background-size: 100%;
					z-index: -1;
				}
			}

			.notifications-no {
				font-size: 12px;
				line-height: 17px;
				color: $color-white;
				text-align: center;
				width: 18px;
				height: 18px;
				position: absolute;
				left: -10px;
				top: -5px;
				border-radius: 50%;
				background-color: $color-red-3;
			}
		}
	}

	.copyright {
		position: absolute;
		bottom: 34px;
		right: 15px;
		cursor: pointer;

		img {
			display: block;
		}

		&::before {
			content: "";
			display: block;
			height: 40px;
			width: 44px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -59px;
			border-radius: 20px 0px 0px 20px;
			background-color: $color-gray;
			z-index: -1;
			-webkit-transition: right 0.2s; /* Safari */
			transition: right 0.2s;
			transition-timing-function: ease-out;
		}

		&.footer-open {

			&::before {
				right: -15px;
			}
		}
	}

	&.open {
		width: 150px;

		.main-nav {

			li {

				a {

					span {
						left: 0;
						opacity: 1;
					}
				}
			}
		}
	}
}

.market nav .main-nav li.active::before {
	background-image: url("./img/bg-menu-active-market.png");
}

.market.stats nav .main-nav li.active::before {
	background-image: url("./img/bg-menu-active-market.png");
}

.stats nav .main-nav li.active::before {
	background-image: url("./img/bg-menu-active-stats.png");
}

.tops nav .main-nav li.active::before {
	background-image: url("./img/bg-menu-active-tops.png");
}


@media (max-width: 575px) {
	nav {
		height: auto;
		width: 100%;
		top: auto;
		bottom: 0;

		.nav-inner-wrapper {
			margin-top: 0;
		}

		.menu-logo {
			display: none;
		}

		.main-nav {
			display: none;
			height: 49px;
			margin-top: 0;
			padding-left: 0;
			text-align: center;
			justify-content: space-evenly;
			align-items: center;

			li {
				margin-bottom: 0;
				margin-left: 0;

				&.profile-info-mobile {
					display: inline-block;
				}

				a {

					img {
						padding-right: 0;
					}

					span {
						display: none;
					}
				}

				&.active {

					a {
						img {
							padding-right: 0;
						}
					}

					&::before {
						height: 49px;
						width: 72px;
						top: -9px;
						left: 50%;
						background-image: url("./img/bg-menu-active-mobile.png");
						transform: translateX(-50%);
					}
				}
			}
		}

		.copyright {
			display: none;
		}

		&.open {
			width: 100%;
		}
	}

	.home,
	.live,
	.results,
	.index {

		nav {
			.main-nav {
				display: flex;
			}
		}
	}

	.market nav .main-nav li.active::before {
		background-image: url("./img/bg-menu-active-mobile-market.png");
	}

	.stats nav .main-nav li.active::before {
		background-image: url("./img/bg-menu-active-mobile-stats.png");
	}

	.tops nav .main-nav li.active::before {
		background-image: url("./img/bg-menu-active-mobile-tops.png");
	}
}
