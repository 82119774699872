@import '../../variables.scss';

.avatar {
  display: inline-block;
  height: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  vertical-align: middle;

  a {
    display: block;
    position: relative;

    .image {
      display: block;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
    }

    .overlay {
      -js-display: flex;display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;

      p {
        font-size: 14px;
        font-weight: 700;
        color: $color-white;
      }
    }
  }
}
