@import '~AppSrc/variables.scss';

.a2hs {
	&.visible {
		opacity: 1;
		visibility: visible;
		transform: translateY(0px);
		transition: transform 0.25s ease;
	}

	transition: transform 0.25s ease;

	.btn {
		&:first-child {
			width: 40%;
		}

		background-color: transparent;
		border-radius: 10px;
		color: $color-white;
		flex-grow: 1;
		border: 0;
		outline: 0;
		padding: 15px;
	}

	position: fixed;
	transform: translateY(50px);
	bottom: 0;
	left: 0;
	z-index: 100;
	width: 100vw;
	background-color: #5C426E;
	opacity: 0;
	visibility: hidden;
	-js-display: flex;display: flex;
	align-items: center;
	justify-content: space-between;
}
