@import '../../variables.scss';

header {
  .top-menu {
    width: 100%;
		height: 56px;
		display: flex;
    position: fixed;
		justify-content: space-between;
    padding-left: 50px;
    background-color: $color-dark-main;
    -webkit-transition: padding-left 0.2s; /* Safari */
    transition: padding-left 0.2s;
    transition-timing-function: ease-out;
    box-sizing: border-box;
    z-index: 10;

		.menu-left {
			margin-left: 20px;
			display: flex;
			align-self: center;

			button {
				width: 32px;
				height: 32px;
				background-color: $color-purple;
				margin-right: 8px;
				padding: 0;
				border: none;
				border-radius: calc(32px / 2);
				display: flex;
				justify-content: center;
				cursor: pointer;

				img {
					width: 16px;
					height: 16px;
					align-self: center;
				}
			}

			.menu-left-right {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.menu-left-right-top {
					div {
						display: inline;

						p,
						a {
							line-height: 11px;
							display: inline;
							font-weight: 400;
							font-size: 11px;
							color: #92879A;
						}

						p {
							margin: 0 5px;
						}

						a {
							text-decoration: none;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}

				p.menu-left-right-bottom {
					line-height: 18px;
					font-weight: 700;
					font-size: 18px;
					color: #ffffff;

					span {
						line-height: 19px;
						position: relative;
						display: inline-block;
						margin-left: 20px;
						font-weight: 400;
						font-size: 14px;
						color: #8D8295;
						min-width: 150px;

						&:before {
							content: "";
							display: block;
							height: 19px;
							width: 1px;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: -10px;
							background-color: rgba(255, 255, 255, 0.1);
						}
					}
				}
			}
		}

    .menu-right {
			margin-right: 20px;
			display: flex;
			align-self: center;

			.auth-links {
				display: flex;
				align-items: center;

				li {
					line-height: 20px;

					a {
						font-weight: 600;
						font-size: 11px;
						color: $color-white;
					}

					&:first-child {
						padding-right: 15px;
						border-right: 1px solid $color-dark-2;
					}

					&:last-child {
						padding-left: 15px;
					}
				}
			}

			a.icon-logo {
				width: 32px;
				height: 32px;
				margin-right: 16px;
				align-self: center;
				display: none;

				img {
					width: auto;
					height: auto;
					max-width: 100%;
				}
			}

			p.xfc-currency {
				line-height: 11px;
				margin-right: 16px;
				align-self: center;
				font-weight: 400;
				font-size: 11px;
				color: $color-text-2;
			}

			> a {
				margin-right: 16px;
				align-self: center;

				button.buy-xfc {
					height: 36px;
					background-color: $color-red;
					padding: 0 8px;
					border: none;
					border-radius: 8px;
					font-weight: 600;
					font-size: 12px;
					color: $color-white;
					cursor: pointer;
				}
			}

			.xfc-gold-container {
				height: 38px;
				margin-right: 16px;
				padding: 0 8px;
				border: 1px solid $color-dark-2;
				border-radius: 8px;
				display: flex;

				p {
					line-height: 12px;
					margin-right: 8px;
					font-weight: 400;
					font-size: 12px;
					color: $color-text-2;
					align-self: center;
				}

				.xfc-gold-container-left {
					padding-right: 16px;
					align-self: center;
					display: flex;
					border-right: 1px solid $color-dark-2;

					img.icon-wallet {
						width: 22px;
						height: 20px;
						margin-right: 16px;
						align-self: center;
					}

					img.icon-xfc {
						width: 24px;
						height: 24px;
						align-self: center;
					}
				}

				.xfc-gold-container-right {
					padding-left: 16px;
					align-self: center;
					display: flex;

					img.icon-gold {
						width: 24px;
						height: 24px;
						align-self: center;
					}
				}
			}

			p.user-level {
				line-height: 12px;
				margin-right: 8px;
				align-self: center;
				font-weight: 400;
				font-size: 12px;
				color: $color-text-2;
			}

      .profile-dropdown {
        height: 100%;
        display: inline-block;
        position: relative;

        .profile-avatar {
          position: relative;

					.notifications-no {
						line-height: 15px;
						font-size: 12px;
						color: $color-white;
						text-align: center;
						width: 18px;
						height: 18px;
						position: absolute;
						left: -10px;
						top: -5px;
						border-radius: 50%;
						background-color: $color-red-3;
					}
        }

        .dropdown-icon {
          padding-left: 4px;
        }

        .dropdown {
          width: 183px;
          position: absolute;
          top: 100%;
          right: 0;
        }
      }
    }

    &.nav-opened {
      padding-left: 150px;
    }
  }
}

@media (max-width: 840px) {
	header {
		.top-menu {
			.menu-left {
				margin-right: 20px;
			}

			.menu-right {
				p.xfc-currency {
					display: none;
				}

				> a {
					button.buy-xfc {
						height: 32px;
						font-weight: 400;
						font-size: 11px;
					}
				}

				.xfc-gold-container {
					height: auto;
					border-radius: 0;
					border: none;

					.xfc-gold-container-left {
						img.icon-wallet {
							display: none;
						}
					}

					.xfc-gold-container-right {
					}
				}
			}
		}
	}
}

@media (max-width: 575px) {
  header {
    .top-menu {
			height: 88px;
      position: relative;
      padding: 0;
			flex-direction: column;

			.menu-left {
				width: 100%;
				height: 48px;
				margin: 0;
				padding: 8px 10px;
				box-sizing: border-box;
				order: 2;
			}

      .menu-right {
				width: 100%;
				height: 40px;
        display: flex;
				justify-content: space-between;
        padding: 0 10px;
				box-sizing: border-box;
        transform: none;
				order: 1;
				margin-right: 0;

				&.not-auth {
					a.icon-logo {
						order: 1;
					}

					ul.auth-links {
						order: 2;
					}
				}

				a.icon-logo {
					margin-right: 0;
					display: block;
					order: 1;
				}

				.xfc-gold-container {
					margin-right: 0;
					order: 2;
				}

				> a {
					margin-right: 0;
					order: 3;
				}

				p.user-level {
					display: none;
				}

				.profile-dropdown {
					display: none;
				}

        .avatar {
          display: none;
        }

				.notifications-no {
					display: none;
				}
      }

      .profile-dropdown {
        .dropdown-icon {
          display: none;
        }
      }

      &.nav-opened {
        padding-left: 0;
      }
    }
  }
}
