@import '../../variables.scss';

.dropdown {
	//width: 100%;
	padding: 20px;
	background-color: $color-white;
	border-radius: 8px;
	box-sizing: border-box;
	overflow: hidden;
	transition: transform 0.1s ease-out;
	height: auto;
	transform: scaleY(0);
	transform-origin: top;


	ul {

		li {
			margin-bottom: 15px;

			a {
				font-size: 12px;
				line-height: normal;
				font-weight: 400;
				color: $color-dark-3;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.active {
		transform: scaleY(1);

		ul {
		}
	}
}
