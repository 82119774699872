@import '../../variables.scss';

.ReactModal__Content.offline-page {
	height: 100vh !important;
	width: 100vw !important;
	top: 0 !important;
	left: 0 !important;
	transform: translate(0,0) scale(1) !important;
	transition: all 0.2s ease !important;
}

.offline-page {
	height: 100vh !important;
	width: 100vw !important;
	top: 0 !important;
	left: 0 !important;
	transform: translate(0,0) scale(1) !important;
	font-weight: 400;
	z-index: 100;

	.logo-container {
		background: linear-gradient(180deg, $color-dark-main -4.49%, #5C426E 100%);
		height: 70vh;
		position: relative;
		-js-display: flex;display: flex;
		align-items: center;
		justify-content: center;

		.logo {
			z-index: 1;
			margin: auto;
			display: block;
			width: 40%;
			max-width: 151px;
		}

		.brush {
			width: 100%;
			position: absolute;
			bottom: -1px;
		}
	}

	.splash {
		text-align: center;
		padding: 0 10px;

		.connection {
			color: $color-text-3;
			font-size: 18px;
			line-height: 22px;
			margin-top: 13px;
		}

		@keyframes blink {
			0% {
				background-color: $color-dark-main;
			}
			20% {
				background-color: transparent;
			}
			100% {
				background-color: $color-dark-main;
			}
		}

		.bullets {
			span {
				width: 6px;
				height: 6px;
				background-color: $color-dark-main;
				border: 1px solid $color-dark-main;
				border-radius: 50%;
				display: inline-block;
				margin: 0 6px;
				animation-name: blink;
				animation-duration: 1s;
				animation-iteration-count: infinite;
				animation-fill-mode: both;

				&:nth-child(2) {
					animation-delay: 0.2s;
				}

				&:nth-child(3) {
					animation-delay: 0.4s;
				}

				&:nth-child(4) {
					animation-delay: 0.6s;
				}

				&:nth-child(5) {
					animation-delay: 0.8s;
				}
			}
		}
	}

}
