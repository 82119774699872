@import '../../variables';

.errors-modal,
.general-modal {

	.title {
		margin-bottom: 15px;
	}
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 12;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.6)!important;

  .close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
		z-index: 1;
  }
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content {
  width: 355px;
  padding: 0 !important;
  transform: scale(0.3);
  transition: all 0.3s;
  background: $color-bg-main;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	outline: 0;

  .title {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 60px;
    border-radius: 8px 8px 0 0;

    h5 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
  }

  .content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .buttons {
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
}

.ReactModal__Content--after-open {
  transform: translate(-50%, -50%) scale(1)!important;
}


@media (max-width: 375px) {
  .ReactModal__Content {
    width: 310px;
  }
}
