@import '../../variables.scss';

.btn {
  position: relative;
  display: inline-block;
  padding-top: 13px;
  padding-bottom: 15px;
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
	appearance: none;
	background: transparent;
	outline: 0;
	border: 0;
	text-align: center;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

  p {
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
  }

  &.btn-small-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-mobile-border {
    display: none;
  }
}


@media (max-width: 575px) {
  .btn {

    .btn-mobile-border {
      display: block;
      position: absolute;
      top: -18px;
      left: 0;
      right: 0;
      height: 3px;
      width: 100%;
      border-radius: 0 0 8px 8px;
    }
  }
}
