@import "../../variables";

.notification-card-component {
	max-width: 400px;
	position: absolute;
	z-index: 20;
	right: 0;
	top: 50px;

	.notification-card {
		background: $color-white;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 15px;

		p {
			font-size: 14px;
			line-height: 17px;
			color: $color-dark-1;
			margin-bottom: 10px;
		}

		.notification-card-title {
			margin-bottom: 7px;
			-js-display: flex;display: flex;
			justify-content: space-between;
		}

		.actions {
			-js-display: flex;display: flex;
			align-items: center;
			justify-content: space-between;

			li {
				margin-left: 20px;

				&:first-child {
					margin-left: 0;
				}

				.sub-actions {
					-js-display: flex;display: flex;
					align-items: center;

					li {
						margin-left: 20px;
						position: relative;

						&:first-child {
							margin-left: 0;

							&::after {
								content: "";
								display: block;
								height: 100%;
								width: 1px;
								background-color: $color-dark-1;
								position: absolute;
								right: -11px;
								top: 0;
							}
						}

						a {
							font-size: 14px;
							color: $color-dark-1;
						}
					}
				}
			}
		}

		a {
			font-size: 14px;
			line-height: 17px;
			font-weight: 700;
			color: $color-red-3;
			text-transform: uppercase;
		}
	}
}

.notification-alert-enter {
	top: -50px;
	opacity: 0;
}
.notification-alert-enter-active {
	opacity: 1;
	top: 50px;
	transition: all 200ms;
}
.notification-alert-exit {
	opacity: 1;
	top: 50px;
}
.notification-alert-exit-active {
	opacity: 0;
	top: -50px;
	transition: all 200ms;
}

